// Generated by Framer (b75150a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Q9uDGqGcC", "w5IlF2lg8"];

const serializationHash = "framer-utsOf"

const variantClassNames = {Q9uDGqGcC: "framer-v-pvqhuj", w5IlF2lg8: "framer-v-1ttf7rk"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Glam: "Q9uDGqGcC", Light: "w5IlF2lg8"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Q9uDGqGcC"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Q9uDGqGcC", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-pvqhuj", className, classNames)} data-framer-name={"Glam"} layoutDependency={layoutDependency} layoutId={"Q9uDGqGcC"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} {...addPropertyOverrides({w5IlF2lg8: {"data-framer-name": "Light"}}, baseVariant, gestureVariant)}><motion.div className={"framer-c0ymbo"} data-framer-name={"Fill"} layoutDependency={layoutDependency} layoutId={"U_ne3Y_wy"} style={{background: "linear-gradient(270deg, #4eb 0%, #4be 50%, #b4e 100%)"}} variants={{w5IlF2lg8: {background: "linear-gradient(270deg, var(--token-053cd767-eee8-48bc-912d-e4ad11d173ab, rgb(174, 182, 190)) /* {\"name\":\"Background/Light\"} */ 0%, var(--token-053cd767-eee8-48bc-912d-e4ad11d173ab, rgb(174, 182, 190)) /* {\"name\":\"Background/Light\"} */ 50%, var(--token-053cd767-eee8-48bc-912d-e4ad11d173ab, rgb(174, 182, 190)) /* {\"name\":\"Background/Light\"} */ 100%)"}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-utsOf.framer-18svgmg, .framer-utsOf .framer-18svgmg { display: block; }", ".framer-utsOf.framer-pvqhuj { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 2px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 240px; will-change: var(--framer-will-change-override, transform); }", ".framer-utsOf .framer-c0ymbo { flex: 1 0 0px; height: 1px; overflow: hidden; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-utsOf.framer-pvqhuj { gap: 0px; } .framer-utsOf.framer-pvqhuj > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-utsOf.framer-pvqhuj > :first-child { margin-top: 0px; } .framer-utsOf.framer-pvqhuj > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 2
 * @framerIntrinsicWidth 240
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"w5IlF2lg8":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framervx0fSj0T_: React.ComponentType<Props> = withCSS(Component, css, "framer-utsOf") as typeof Component;
export default Framervx0fSj0T_;

Framervx0fSj0T_.displayName = "Divider";

Framervx0fSj0T_.defaultProps = {height: 2, width: 240};

addPropertyControls(Framervx0fSj0T_, {variant: {options: ["Q9uDGqGcC", "w5IlF2lg8"], optionTitles: ["Glam", "Light"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framervx0fSj0T_, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})